.dataLoading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.dataLoading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
@media (max-width: 576px) {
  .container {
    padding-right: 15px !important;
    padding-left: 25px !important;
  }
}
.view-row {
  margin-bottom: 1em !important;
}
ul.ant-pagination {
  align-items: center !important;
  padding-right: 21px !important;
}
button.ant-drawer-close {
  margin-left: -6px !important;
}
.no-border-radius input {
  border-radius: unset !important;
}
.ant-tooltip-inner span:nth-of-type(2) {
  margin-left: 6px !important;
}
.no-background-color {
  background-color: unset !important;
}
.force-center {
  text-align: center !important;
}
.force-header-height-146 {
  height: 146px !important;
}
.avatar-rounded {
  border-radius: 50% !important;
}
/* set background color for message toast */
.ant-message-notice-content {
  background-color: #424242 !important;
  color: white !important;
}
.message-info .ant-message-notice-content {
  background-color: white !important;
  color: black !important;
}
.ant-input-disabled,
.ant-input[disabled],
.ant-picker.ant-picker-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgb(247 247 496) !important;
  color: rgb(160 160 160) !important;
}
.ant-picker-input > input[disabled] {
  color: rgb(160 160 160) !important;
}
.ant-notification-notice.ant-notification-notice-error {
  border: 1px solid #ff6b72 !important;
}
.ant-notification-notice.ant-notification-notice-warning {
  border: 1px solid rgb(255 197 66) !important;
}
.ant-notification-notice.ant-notification-notice-info {
  border: 1px solid #3e79f7 !important;
}
.ant-notification-notice.ant-notification-notice-success {
  border: 1px solid #04d182 !important;
}
.module-container {
  padding: 25px !important;
}
.view-row {
  margin-bottom: 1em !important;
}
ul.ant-pagination {
  align-items: center !important;
  padding-right: 21px !important;
}
button.ant-drawer-close {
  margin-left: -6px !important;
}
.no-border-radius input {
  border-radius: unset !important;
}
.disabled {
  color: lightgray !important;
}
.ant-input-prefix {
  margin-right: 8px !important;
}
.ant-form-item-explain {
  top: 2.6rem !important;
  font-size: 12px !important;
  width: 100% !important;
  padding-left: 8px !important;
}
form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none !important;
}
.sending-sms {
  position: absolute !important;
  margin-top: -20px !important;
  text-align: center !important;
  width: 100% !important;
  font-size: 12px !important;
  color: #8282f7 !important;
}
.sending-sms-atualiza {
  text-align: center !important;
  font-size: 12px !important;
  color: #8282f7 !important;
  min-height: 24px !important;
  margin-bottom: 16px !important;
}
.agenda-settings-container {
  margin-bottom: 48px !important;
}
.agenda-settings-card {
  width: 368px !important;
  margin: 16px 8px 8px !important;
}
.card-clickable-effect:hover {
  background: #ededed !important;
  cursor: pointer !important;
}
.agenda-settings-card.add-agenda {
  width: 368px !important;
  height: 128px !important;
  font-size: 36px !important;
  color: grey !important;
  opacity: 0.4 !important;
}
.agenda-settings-card .tag {
  margin: 16px 4px 0 !important;
}
.calendar-scheduling .ant-picker-calendar-mode-switch {
  content-visibility: hidden !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  border: 1px gray solid !important;
  background-color: unset !important;
  color: gray !important;
}
li.ant-picker-ok,
li.ant-picker-ok button {
  width: 100% !important;
}
.form-item-hour input {
  border-radius: unset !important;
}
.checkbox-like-radio.ant-checkbox-group {
  box-sizing: border-box !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #455560 !important;
  font-variant: tabular-nums !important;
  line-height: 1.5 !important;
  list-style: none !important;
  -webkit-font-feature-settings: "tnum" !important;
          font-feature-settings: "tnum" !important;
  display: inline-block !important;
  font-size: 0 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper {
  position: relative !important;
  display: inline-block !important;
  height: 40px !important;
  margin: 0 0 0 -1px !important;
  padding: 0 15px !important;
  color: #455560 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  background: #ffffff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e6ebf1 !important;
  border-image: initial !important;
  cursor: pointer !important;
  transition: color 0.3s ease 0s, background 0.3s ease 0s, border-color 0.3s ease 0s, box-shadow 0.3s ease 0s !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-inner {
  visibility: hidden;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
  z-index: 1 !important;
  color: #3e79f7 !important;
  background: #ffffff !important;
  border: 1px solid #3e79f7 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
  border-color: #a2a2a3 !important;
  z-index: 1;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled:first-child {
  border-left-color: #a2a2a3 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: -1 !important;
  width: 100% !important;
  height: 100% !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:hover:not(.ant-checkbox-wrapper-disabled) span {
  color: #699dff !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox-inner {
  display: table-row !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:first-child {
  margin: 0 !important;
  border-left: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem 0 0 0.625rem !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:first-child.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
  border-left: 1px solid #3e79f7 !important;
}
.checkbox-like-radio.ant-checkbox-group .ant-checkbox-wrapper:last-child {
  border-radius: 0 0.625rem 0.625rem 0 !important;
}
.tag-col-container .ant-tag {
  margin-top: 8px !important;
}
.tag-col-container:last-child .ant-tag {
  margin-right: 0 !important;
}
.progress .ant-progress-text {
  text-align: right !important;
}
.like-a-card {
  margin-bottom: 24px !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
}
.ant-page-header-heading-title {
  min-height: 40px !important;
}
.text-lowercase input {
  text-transform: lowercase !important;
}
.ant-table-filter-dropdown {
  padding: 1px !important;
}
.ant-table-filter-dropdown .ant-input-affix-wrapper::before {
  content: unset !important;
}
.ant-table-filter-dropdown .ant-input-affix-wrapper input {
  width: 90% !important;
}
.ant-table-filter-dropdown .ant-input-affix-wrapper span.ant-input-suffix {
  float: right !important;
  margin-top: 5px !important;
}
.ant-form-vertical .ant-form-item-label > label {
  width: 100% !important;
}
.menu-icon-search svg {
  width: 18px !important;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left !important;
  width: 200px !important;
  margin-right: 8px !important;
}
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right !important;
}
.ant-upload-span .ant-upload-list-item-card-actions .anticon-delete svg {
  margin-top: -4px !important;
}
div.attachments {
  display: flex !important;
  margin-top: 30px !important;
}
div.attachments .attachment-item {
  cursor: pointer !important;
  border-radius: 0.3rem !important;
  border: 1px solid #e6ebf1 !important;
  padding: 5px 10px !important;
  margin-right: 15px !important;
  display: flex !important;
  align-items: center !important;
}
div.attachments .attachment-item .anticon {
  font-size: 2rem !important;
}
.ocurrence-update-ticket {
  padding: 0 0 36px !important;
}
.drawer-chat .ant-drawer-body {
  padding: 0 !important;
  overflow: hidden;
}
.drawer-chat .chat {
  overflow: hidden;
}
.drawer-chat .chat .chat-content .chat-content-body {
  height: calc(((100vh - 40px) - 60px) - 30px) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.drawer-chat .chat .chat-content .chat-content-body > div > div > div {
  margin-top: 16px;
}
.chat .chat-content .chat-content-body {
  padding-right: 4px !important;
}
.chat .chat-content .chat-content-body > div > div {
  margin-bottom: -24px !important;
  padding-right: 4px !important;
}
.chat .chat-content .chat-content-body > div > div::-webkit-scrollbar {
  width: 10px !important;
}
.chat .chat-content .chat-content-body > div > div::-webkit-scrollbar-thumb {
  border: 2px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 9999px !important;
  background-color: #e1e1e1 !important;
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.ant-timeline-item-head-custom {
  background: none !important;
}
@media (min-width: 768px) {
  .ocurrence-update-ticket {
    padding: 0 96px 36px !important;
  }
  .select-subscription-header {
    max-width: unset !important;
    min-width: 180px !important;
    padding-right: 16px !important;
  }
  .ant-drawer-content-wrapper {
    width: 378px !important;
  }
}
/*
svg.owner-svg {
  //-webkit-text-shadow: 0 0 9px 10px #C0C0C0;
  text-shadow: 0 0 9px 10px #C0C0C0;
}
*/
.rounded-avatar-profile-icon {
  position: absolute !important;
  z-index: 1 !important;
  margin-left: 28px !important;
  margin-top: 28px !important;
}
.nav-profile-icon .rounded-avatar-profile-icon {
  position: absolute !important;
  z-index: 1 !important;
  margin-left: 28px !important;
  margin-top: 42px !important;
}
.select-subscription-header .ant-select-selection-search input {
  padding-left: 18px !important;
}
.ant-tabs-tab {
  margin: 0 !important;
}
.editable-tab .editable-cell-value-wrap {
  min-height: 34px !important;
  padding: 10px 12px 9px !important;
}
button.fixed-with-90 {
  min-width: 90px !important;
}
.modal-without-padding .ant-modal-body {
  padding: 0 !important;
}
.basic-padding {
  padding: 16px 24px 24px !important;
}
.modal-without-bottom-padding .ant-modal-body {
  padding: 24px 24px 0 !important;
}
.modal-without-top-padding .ant-modal-body {
  padding-top: 0 !important;
}
button.circle-button-in-line {
  margin: 2px 16px !important;
}
.selector-with-100 .ant-input-number-input-wrap {
  max-width: 100px !important;
}
.selector-with-100 .ant-select-selector {
  min-width: 100px !important;
}
.antd-react-color .antd-react-color-popover {
  left: -238px !important;
}
.antd-react-color .antd-react-color-popover div[style*="left: 12px"] {
  left: unset !important;
  right: 9px !important;
}
.antd-react-color .antd-react-color-popover.type-1 {
  left: -222px !important;
}
.antd-react-color.compact .antd-react-color-popover {
  left: -202px !important;
}
.antd-react-color.compact .antd-react-color-picker {
  width: 240px !important;
}
form.inline-form-without-margin .width-100 {
  width: 100% !important;
}
form.inline-form-without-margin .width-100 .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
  margin-bottom: 0 !important;
}
form.inline-form-without-margin .ant-row.ant-form-item {
  margin-right: 0 !important;
}
form.inline-form-without-margin .ant-form-inline.ant-form-item {
  margin-right: 0 !important;
}
form.inline-form-without-margin .ant-form-inline.ant-form-item {
  margin-bottom: 24px !important;
}
form.inline-form-without-margin .no-margin-right .ant-form-item {
  margin-right: 0 !important;
}
div.hide-last-line-table tr.ant-table-row:last-child td.ant-table-cell {
  border-bottom: unset !important;
}
div.hide-last-line-table tr.ant-table-placeholder td.ant-table-cell {
  border-bottom: unset !important;
}
.min-height-220 {
  min-height: 220px !important;
}
.fix-td-height-73 td {
  height: 73px !important;
}
.ant-tag-magenta {
  color: #c41d7f !important;
  background: #fff0f6 !important;
  border-color: #ffadd2 !important;
}
.ant-tag-red {
  color: #cf1322 !important;
  background: #fff1f0 !important;
  border-color: #ffa39e !important;
}
.ant-tag-volcano {
  color: #d4380d !important;
  background: #fff2e8 !important;
  border-color: #ffbb96 !important;
}
.ant-tag-orange {
  color: #d46b08 !important;
  background: #fff7e6 !important;
  border-color: #ffd591 !important;
}
.ant-tag-gold {
  color: #d48806 !important;
  background: #fffbe6 !important;
  border-color: #ffe58f !important;
}
.ant-tag-lime {
  color: #7cb305 !important;
  background: #fcffe6 !important;
  border-color: #cae88d !important;
}
.ant-tag-green {
  color: #389e0d !important;
  background: #f6ffed !important;
  border-color: #b7eb8f !important;
}
.ant-tag-cyan {
  color: #08979c !important;
  background: #e6fffb !important;
  border-color: #87e8de !important;
}
.ant-tag-blue {
  color: #096dd9 !important;
  background: #e6f7ff !important;
  border-color: #91d5ff !important;
}
.ant-tag-geekblue {
  color: #1d39c4 !important;
  background: #f0f5ff !important;
  border-color: #adc6ff !important;
}
.ant-tag-purple {
  color: #531dab !important;
  background: #f9f0ff !important;
  border-color: #d3adf7 !important;
}
.ant-tag-yellow {
  color: #d4b106 !important;
  background: #feffe6 !important;
  border-color: #ffe58f !important;
}
.sidenav-menu .ant-drawer-content-wrapper {
  max-width: 320px !important;
}
input#analystFormToInsertValue_user {
  padding-left: 4px !important;
}
.chat .chat-content .chat-content-body .msg .bubble {
  max-width: 100% !important;
}
.inner-app-layout-with-head {
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.625rem !important;
  margin-bottom: 24px !important;
}
.inner-app-layout-with-head .side-content {
  padding: 6px 0 6px 0 !important;
}
.inner-app-layout-with-head.TOP .main-content {
  min-height: calc(((100vh - 448px) - 50px) - 2px) !important;
}
.inner-app-layout-with-head.SIDE .main-content {
  min-height: calc(((100vh - 380px) - 50px) - 2px) !important;
}
.card-with-picture {
  min-height: 152px !important;
  margin-bottom: 10px !important;
}
.card-with-picture .ant-card-body {
  min-height: 213px !important;
}
.card-with-creator-data .ant-card-body {
  padding-bottom: 8px !important;
}
.flat-table thead.ant-table-thead {
  display: none !important;
}
.flat-table tbody.ant-table-tbody td.ant-table-cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ant-breadcrumb {
  margin-bottom: 3px !important;
}
.layout-left-nav .form-without-content-padding {
  margin: -25px !important;
}
.ant-spin-blur::after {
  opacity: 0 !important;
}
.multi-tags {
  margin: 4px 8px 16px 0 !important;
}
.multi-tags.unique {
  margin: 4px 0 16px 0 !important;
}
.multi-tags + .multi-tags:last-of-type {
  margin-right: 0 !important;
}
.ant-input-suffix .ant-input-clear-icon {
  margin-top: -3px !important;
}
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  margin-top: 4px !important;
}
.menu-with-version ul {
  width: 90% !important;
}
.photo-module-form-header {
  background-color: #FFFFFF !important;
  border-radius: 10px !important;
  margin-top: -48px !important;
  min-height: 158px !important;
  min-width: 158px !important;
  max-height: 158px !important;
  max-width: 158px !important;
}
.photo-module-form-header .ant-upload-list-picture-card-container,
.photo-module-form-header .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin: 0 !important;
  width: 158px !important;
  height: 158px !important;
}
.photo-module-form-header .ant-upload-list-picture-card-container button span.anticon.anticon-delete,
.photo-module-form-header .ant-upload.ant-upload-select.ant-upload-select-picture-card button span.anticon.anticon-delete {
  margin-left: 18px !important;
  margin-top: -6.5px !important;
}
.icon-in-list {
  color: #ff4d4f !important;
  color: gray !important;
  font-size: 24px !important;
  padding: 10px !important;
  margin-right: 14px !important;
}
.fix-first-and-last-row tbody tr:first-child,
.fix-first-and-last-row tbody tr:last-child {
  pointer-events: none !important;
}
.table-disabled-row {
  color: #a0a0a0 !important;
  background-color: #f7f7ff38 !important;
  pointer-events: none !important;
}
.svg-size-24 svg {
  width: 24px !important;
  height: 24px !important;
}
.page-header-alt.is-sidenav {
  margin-right: 0 !important;
}
.title-no-margin .ant-card-meta-avatar {
  padding-right: 8px !important;
}
.title-no-margin .ant-card-meta-title {
  margin-bottom: 0 !important;
}
.ant-spin-blur {
  opacity: 0.1 !important;
}
.ant-form-item label {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.width-100-percent {
  width: 100% !important;
}
.ant-upload-list-picture-card span.anticon.anticon-delete svg {
  margin-top: -6px !important;
  margin-right: -18px !important;
}
.no-margin .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin-right: 0 !important;
}
.no-margin .ant-upload-list-picture-card .ant-upload-list-item-info:before {
  margin-left: -43.5px !important;
}
.ant-dropdown-trigger.ellipsis-dropdown {
  padding: 8px !important;
}
div.ant-col.small-icon svg {
  padding-top: 4px !important;
}
.picture-icon .ant-upload-list-item-info {
  background-color: #ecf2fe !important;
}
.picture-icon .ant-upload-span {
  padding: 40px !important;
}
.with-checkbox .ant-table-selection-column {
  padding-left: 24px !important;
}
li.ant-menu-item svg {
  margin-bottom: -3px !important;
}
aside.side-nav li.ant-menu-item svg,
.inner-app-layout li.ant-menu-item svg {
  margin-bottom: 0 !important;
}
.breadcrumb-fix-svg svg {
  margin-bottom: -2px !important;
}
.ant-menu-submenu-title svg {
  margin-bottom: -3px !important;
}
.badge-size-12 .ant-badge-status-dot {
  width: 12px !important;
  height: 12px !important;
}
.only-centered-icons.ant-segmented .ant-segmented-item svg {
  margin-bottom: -3px;
}
.ant-card.title-with-switch .ant-card-head-title .ant-form-item {
  margin: 0 !important;
}
.hide-percentage .ant-progress-text {
  opacity: 0 !important;
}
.dataLoading .ant-spin {
  color: #3e79f7;
}
